import { useRef } from 'react';
import { warning } from "./internal/warning.js";
import { usePrevious } from "./usePrevious.js"; // eslint-disable-next-line @typescript-eslint/no-explicit-any

export function areDepsEqualWith(hookName, next, prev, isEqual) {
  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'production') {
    warning(next.length !== prev.length, "The final argument passed to " + hookName + " changed size between renders. " + 'The order and size of this array must remain constant.\n\n' + ("Previous: [" + prev + "]\n") + ("Incoming: [" + next + "]"));
  }

  for (var i = 0; i < prev.length && i < next.length; i++) {
    if (!isEqual(next[i], prev[i])) {
      return false;
    }
  }

  return true;
}
export function usePureDeps(deps, isEqual) {
  if (isEqual === void 0) {
    isEqual = Object.is;
  }

  var ref = useRef(deps);
  var prev = usePrevious(deps);

  if (prev && !areDepsEqualWith('usePureDeps', deps, prev, isEqual)) {
    ref.current = deps;
  }

  return ref.current;
}