function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import { useReducer } from 'react';
import { useEventCallback } from "./useEventCallback.js";
import { useMemoWith } from "./useMemoWith.js";
import { usePromiseEffect } from "./usePromiseEffect.js";
import { usePureDeps } from "./usePureDeps.js";
export function reducePromiseState(state, action) {
  switch (action.type) {
    case 'pending':
      return state.status === 'pending' ? state : {
        status: 'pending'
      };

    case 'fulfill':
      return {
        status: 'fulfilled',
        value: action.payload
      };

    case 'reject':
      return {
        status: 'rejected',
        error: action.payload
      };
  }

  return state;
}

function isEqualPromiseState(a, b) {
  return a.status === b.status && Object.is(a.error, b.error) && Object.is(a.value, b.value);
}

export function usePromise(factory, deps, _temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      {
    reducer = reducePromiseState
  } = _ref,
      options = _objectWithoutPropertiesLoose(_ref, ["reducer"]);

  var pureDeps = usePureDeps(deps);
  var pureFactory = useEventCallback(factory);
  var [state, dispatch] = useReducer(reducer, {
    status: 'pending'
  }, prev => reducer(prev, {
    type: 'init'
  }));
  usePromiseEffect(pureFactory, pureDeps, Object.assign({}, options, {
    onPerform() {
      dispatch({
        type: 'pending'
      });
    },

    onFulfill(payload) {
      dispatch({
        type: 'fulfill',
        payload
      });
    },

    onReject(payload) {
      dispatch({
        type: 'reject',
        payload
      });
    }

  }));
  return useMemoWith(() => state, [state], isEqualPromiseState);
}